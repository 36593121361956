import React from 'react';
import Main from './Components/Main';
import './Components/style.css'
function App() {
  return (
    <>
      <Main/>
    </>
  );
}

export default App;
